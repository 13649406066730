// Import Fuse core library
@use '@angular/material' as mat;
@import '@fuse/scss/core';

// Import app.theme.scss
@import 'app/app.theme';

//-- Enable disabled button pointer events
button:disabled {
  pointer-events: auto !important;
  cursor: default;
}

//-- Custom typography
.mat-mdc-checkbox {
  font-size: 16px;
}
// input name text
.mdc-floating-label mat-label {
  font-size: 16px;
}

//-- Google maps controls
@media (pointer: fine) {
  //Reduces the control size for desktop users
  .gm-bundled-control,
  .gm-style-mtc,
  .gm-fullscreen-control {
    transform: scale(0.7) translate(10px, 30px);
  }
}

//-- material chips styles
.mat-mdc-standard-chip {
  --mdc-chip-container-height: unset !important;
  font-family: Lato, Roboto, Helvetica Neue, Arial, 'sans-serif' !important;
}

/** SET REQUIRED AS BIG! **/
// Sets the asterix size for the required form fields
.mat-mdc-form-field-required-marker {
  vertical-align: text-top;
  font-size: 18px;
  padding-right: 1px;
}

// Lists normally have to fixed 24 padding with p-24. However, that it not suitable for mobile widths
.list-p-24 {
  padding: 24px;
  @include media-breakpoint('xs') {
    padding: 8px !important;
  }
}
.details-p-24 {
  // This should stay agnostic towards bottom and top paddings
  padding-left: 24px;
  padding-right: 24px;
  @include media-breakpoint('lt-md') {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.bg-white {
  background-color: #fff !important;
}

.border-bottom-grey {
  border-bottom: 1px solid #e0e0e0 !important;
}

//-- Mat Table
.mdc-data-table__cell {
  padding-left: 8px !important;
  padding-right: 8px !important;
  line-height: 1.4 !important;
  //flex-wrap: wrap !important;
  justify-content: flex-start;
}
.mdc-data-table__header-cell {
  padding-left: 8px !important;
  padding-right: 8px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  overflow: hidden;
  word-wrap: break-word;
  line-height: 1.4 !important;
  flex-wrap: wrap !important;
  place-content: center start !important;
  flex-wrap: wrap !important;
}

// This must be below basic header cell
.right-aligned-header {
  text-align: right !important;
  justify-content: end !important;
}


mat-row mat-cell {
  min-height: 48px;
}

.mat-sort-header-content {
  text-align: left !important;
}

.right-aligned-header .mat-sort-header-container .mat-sort-header-content {
  text-align: right !important;
}

mat-cell:first-of-type, mat-header-cell:first-of-type, mat-footer-cell:first-of-type {
  padding-left: 24px !important;
  @include media-breakpoint(lt-sm) {
    padding-left: 8px !important;
  }
}

mat-cell:last-of-type, mat-header-cell:last-of-type, mat-footer-cell:last-of-type {
  padding-right: 24px !important;
  @include media-breakpoint(lt-sm) {
    padding-right: 8px !important;
  }
}


.ellipsed-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

mat-table {
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
}
.mat-table {
  font-family: Lato, Roboto, Helvetica Neue, Arial, 'sans-serif' !important;
}

mat-header-cell {
  border-radius: 8px 8px 0 0;
  color: #0000008a;
  font-size: 12px;
  font-weight: 700;
}
mat-header-row {
  height: 56px !important;
}
.mat-mdc-row:hover {
  background: lightyellow !important;
  cursor: pointer;
}
.table-element-detail {
  background: white !important;
  cursor: default;
}

.mat-table .mat-cell {
  border-bottom: none;
  line-height: 1.4 !important;
}
mat-table mat-cell p {
  line-height: 1.4 !important;
}

//-- Place holder color
::placeholder {
  color: #777 !important;
  opacity: 1; /* Required by Firefox */
}

/*** Mat info icon ***/
.mat-mdc-form-field-subscript-wrapper .mat-icon,
.mat-mdc-form-field label .mat-icon {
  vertical-align: middle !important;
}

//-- Max height for the drop downs to ensure it never goes out of bounds
.mat-mdc-select-panel {
  max-height: 40vh !important; // Note that this doesn't affect notifications as it is not select-panel type
}


// Styles to non legacy dialog
.mat-mdc-dialog-title {
  font:
    500 20px / 32px Lato,
    Helvetica Neue,
    Arial,
    sans-serif !important;
  letter-spacing: normal !important;
  padding-left: 0 !important;
  padding-bottom: 16px !important;
  line-height: 1.4 !important;
}

mat-dialog-actions {
  padding: 40px !important;
  @include media-breakpoint('lt-sm') {
    padding: 25px 18px 40px 18px !important;
  }
}
.mat-mdc-dialog-content {
  font-family:
    Lato,
    Helvetica Neue,
    Arial,
    sans-serif !important;
  font-weight: 500;
  font-size: 16px !important;
  letter-spacing: unset !important;
  padding: 24px 24px 24px 24px !important;
  line-height: 1.4 !important;
  @include media-breakpoint('lt-sm') {
    padding: 25px 18px 40px 18px !important;
  }

  h3 {
    font-weight: bolder;
  }
  textarea {
    padding-right: 0 !important;
  }
}

mat-mdc-dialog-content {
  font-family:
    Lato,
    Helvetica Neue,
    Arial,
    sans-serif !important;
  letter-spacing: unset !important;
  padding: 40px !important;
  font-size: 16px !important;

  h3 {
    font-weight: bolder;
  }
  textarea {
    padding-right: 0 !important;
  }
}

.mat-mdc-dialog-actions {
  padding: 24px 24px 24px 24px !important;
  justify-content: end !important;
  @include media-breakpoint(lt-sm) {
    padding: 24px 18px 24px 18px !important;
  }
}

//-- Set dialog max height
.mat-mdc-dialog-container {
  max-height: 90vh !important; // mat ui default is 100
}

//-- Notifications
.mat-mdc-menu-panel {
  min-width: 150px !important;
  max-width: 450px !important;
  width: unset !important; // Less empty space when there are only short headers
  @include media-breakpoint(xs) {
    max-width: 80vw !important;
    width: unset !important;
  }
}

.mat-mdc-menu-item {
  font-weight: 600;
}

.mat-mdc-menu-panel {
  padding-top: 0 !important;
}

.mat-mdc-list-base {
  padding-top: 0 !important;
}

.mat-mdc-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

//-- Mat Tooltip Styles
.mat-mdc-tooltip,
.mdc-tooltip {
  max-width: 400px !important;
  overflow: hidden;
  overflow-wrap: anywhere !important;
  display: -webkit-box;
  -webkit-line-clamp: 50; // This sets maximum number of lines before ellipsis
  -webkit-box-orient: vertical;

  font-size: 15px !important;
  font-family:
    Lato,
    Helvetica Neue,
    Arial,
    sans-serif;
  font-weight: 400 !important;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 4px;
  color: white;
  white-space: pre-line;
}

.mdc-tooltip__surface {
  max-width: 400px !important;
}

//-- Checkbox styles
mat-checkbox {
  @include media-breakpoint(gt-xs) {
    max-height: 30px;
  }
}

mat-checkbox .mdc-form-field .mdc-label {
  font-family:
    Lato,
    Helvetica Neue,
    Arial,
    sans-serif;
}

mat-checkbox span {
  font-family:
    Lato,
    Helvetica Neue,
    Arial,
    sans-serif;
  word-break: break-word;
  font-size: 16px;
}

.checkbox-span {
  @include media-breakpoint(lt-md) {
    display: flex;
    line-height: 1.1 !important;
  }
}

// For some reason, by default, the pointer-events for mat-label are disabled.
// Enable pointer events so the tooltips will work
// Disable pointer events if input is disabled (tooltip is not shown for disabled input)
// This may not be relevant fix after legacy updates (11/24)
label.mat-form-field-label {
  pointer-events: auto;

  .mat-form-field-disabled & {
    pointer-events: none;
  }
}

body.theme-default {
  .mat-form-field-appearance-outline {
    &.mat-form-field-warning:not(.mat-form-field-disabled) {
      mat-hint {
        color: #dd8d00;
      }
    }
  }
}

//-- Input border color
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading {
  border-color: rgba(0, 0, 0, 0.12) !important;
  transition: border-color 0.3s ease !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):hover .mdc-notched-outline__leading {
  border-color: rgba(0, 0, 0, 0.5) !important; /* Darker border on hover */
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12) !important;
  transition: border-color 0.3s ease !important;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled):hover .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.5) !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch {
  border-color: rgba(0, 0, 0, 0.12) !important;
  transition: border-color 0.3s ease !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):hover .mdc-notched-outline__notch {
  border-color: rgba(0, 0, 0, 0.5) !important;
}

mat-icon[color='green'] {
  color: green;
}
mat-icon[color='grey'] {
  color: grey;
}
mat-icon[color='red'] {
  color: red;
}
mat-icon[color='customPurple'] {
  color: rgb(126, 36, 185);
}
div.mat-mdc-menu-panel {
  &.extensionsMenu {
    background: none;
    box-shadow: none;
    button {
      margin-bottom: 10px;
    }
  }
}

.y-overflow-scroll {
  overflow-y: scroll;
}

.toolbar-padding {
  padding-top: 58px;
  transition-delay: 1200ms;
  transition-property: padding-top;
}

//-- Adding min height add unnecessary scroll bar even if overflow is set auto
.mat-mdc-paginator-container {
  min-height: unset !important;
}

// mat hint
mat-hint {
  line-height: 16px;
}

//-- Form Fields (input)
input {
  font-size: 16px !important;
}

.mat-mdc-form-field-infix {
  border-top: 4px solid transparent;
}
.mat-mdc-form-field-icon-suffix {
  margin-top: 4px;
}

body.theme-default .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-bottom: 8px;
}

//-- Snackbar Style
.success-snack-bar,
error-snack-bar,
info-snack-bar {
  color: white !important;
  font-family:
    Lato,
    Helvetica Neue,
    Arial,
    sans-serif;
  font-size: 14px;
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
  color: white !important;
  font-family:
    Lato,
    Helvetica Neue,
    Arial,
    sans-serif !important;
  font-size: 14px !important;
}

.mat-mdc-snack-bar-action {
  color: #f3e41a !important;
  font-family:
    Lato,
    Helvetica Neue,
    Arial,
    sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.mat-mdc-snack-bar-container {
  background: #2a2a2a;
  border-radius: 4px;
}

.mat-mdc-snack-bar-container {
  margin: 24px !important;
}

//-- List color styles
.pendingActionRow {
  background-color: #ffffff !important;
}
.completedRow {
  background-color: #f2f2f2 !important;
}
.closedRow {
  background-color: #e5e5e5 !important;
}

.yellowRow {
  background-color: #f3e41a20 !important;
}
.redRow {
  background-color: #bb031220 !important;
}
.grayRow {
  background-color: #bbbbbb !important;
}

//-- Dialog header (mat-toolbar)
mat-toolbar {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.mat-toolbar-row {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

//-- List badge style (as seen in quotation statuses)
.statusBadge {
  padding: 3px 8px 3px 8px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 2px;
  align-items: center;
  border-radius: 3px;
  justify-content: center;
  background: #f0f0f0;
  text-weight: 100;
}

.statusText {
  width: 100%;
  text-align: center;
  display: block;
}
.statusText div {
  text-align: center;
  font-size: 0.8em;
  word-break: break-word;
}

//-- Paginator
.mat-mdc-paginator-range-label {
  font-family: 'Lato', serif;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}

//-- Mat cards
mat-card {
  box-shadow:
    0 2px 1px -1px #0003,
    0 1px 1px #00000024,
    0 1px 3px #0000001f;
  background: white !important;
  font-weight: unset !important;
  font-family: 'Lato', 'Helvetica Neue', Arial, sans-serif !important;
}

mat-card-title {
  padding-left: 0 !important;
  padding-top: 0 !important;
  margin-bottom: 12px !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  font-family: 'Aeonik', 'Quicksand', 'Helvetica Neue', 'Arial', sans-serif;
}

.titleComponent {
  font-weight: 700 !important;
}

//-- Button styles (Consider adding these to theme?)
.mat-mdc-raised-button {
  font-family:
    Lato,
    Helvetica Neue,
    Arial,
    sans-serif;
  font-size: 14px;
  font-weight: 600;
}

button mat-icon {
  font-size: 24px !important;
}

//-- Disabled flat button
.mat-mdc-unelevated-button[disabled] {
  background-color: #0000001f !important;
  color: #00000042 !important;
}

//-- Mat radio
mat-radio-button .mdc-form-field {
  @include media-breakpoint(gt-xs) {
    max-height: 30px !important;
  }
}

.mdc-radio__inner-circle,
.mdc-radio__outer-circle {
  border-color: black !important;
}

mat-radio-button .mdc-form-field .mdc-label {
  font-family:
    Lato,
    Helvetica Neue,
    Arial,
    sans-serif;
  font-size: 16px;
  padding-left: 8px;
  line-height: 16px;
}

.mat-mdc-radio-button {
  --mdc-radio-state-layer-size: 31px !important;
}

//-- Searchbar centralized stylings
.searchbar-content {
  background: white;
  border-radius: 8px;
  .nav {
    &.material2 {
      display: flex;
      @include media-breakpoint(xs) {
        margin-bottom: 16px;
      }
    }
    @include media-breakpoint(gt-sm) {
      .nav-item:not(:nth-child(1)) {
        .nav-link {
          border-radius: 20px !important;
          text-align: center;
          padding: 0 12px 0 12px;
        }
      }
      .nav-item:nth-child(1) {
        .nav-link {
          padding: 0 12px 0 12px;
        }
      }
    }
  }
  mat-form-field {
    margin-bottom: -20px;
  }
  .card {
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
    padding: 0;
    border-radius: 8px;
  }
}

.xs-w-100 {
  @include media-breakpoint(xs) {
    width: 100%;
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Authentication Page Styles
// -----------------------------------------------------------------------------------------------------
// -verify email: with/with out pw.
// -Quick Register: Already exists / new
// -login
// -Reset password
// -forgot password
// Function for returning the tint for a specific color.
// The percentage value has to be the difference between 100 and the the actual tint value
@function tint($color, $percentage) {
  @return mix(#fff, $color, $percentage);
}

$mp-blue: #142438;
$mp-red: #d81159;
$mp-yellow: #f3e41a;
$myplane-grey: #1d1d1b;
$myplane-grey-80: tint($myplane-grey, 20%);
$myplane-grey-70: tint($myplane-grey, 30%);

.auth-page {
  width: 100%;
  overflow: hidden;

  .auth-page-intro {
    background-color: #f3e41a;
    @include media-breakpoint('sm') {
      padding: 128px 64px;
    }

    .title {
      font-size: 10vh;
      font-weight: 300;
      line-height: 1;
      margin-top: 30vh;
      position: relative;
      display: block;
      right: 210px;
      color: $mp-blue;
      @include media-breakpoint(lt-lg) {
        right: 10rem;
      }
    }

    .description {
      padding-top: 16px;
      font-size: 20px;
      max-width: 600px;
    }
  }

  .auth-page-terms {
    font-family: 'Lato', serif;
    font-weight: 300 !important;
    margin-top: 1em;
    color: #555;
  }

  .auth-page-form-wrapper {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    background: url('./assets/images/backgrounds/bg-login.jpeg') no-repeat !important;
    background-size: cover !important;
    padding-left: 80px;
    padding-bottom: 30px;
    @include mat.elevation(16);

    @include media-breakpoint('sm') {
      width: 360px;
      min-width: 360px;
      max-width: 360px;
      padding-left: 0px;
    }

    @include media-breakpoint('xs') {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      padding-left: 0;
    }

    .auth-page-form {
      padding: 128px 48px 48px 48px;
      width: 380px;
      min-width: 380px;
      max-width: 380px;
      @include media-breakpoint('xs') {
        text-align: center;
        margin: 0 auto !important;
        width: unset;
        min-width: unset;
        padding: 20px 20px 0 20px;

        .logo {
          margin-bottom: 10px !important;
        }
      }
      @include media-breakpoint('sm') {
        padding: 100px 48px 0 48px;
        margin: 0 auto !important;
        width: unset;
        min-width: unset;
      }

      .description {
        padding-top: 8px;
        font-family: 'Lato', serif;
      }

      form {
        width: 100%;
        padding-top: 32px;
        padding-left: 0 !important;

        mat-form-field {
          width: 100%;
          @include media-breakpoint('xs') {
            width: 100%;
          }
        }

        .submit-button {
          width: 100%;
          margin: 16px auto;
          display: block;
          height: 50px;
        }

        .active {
          color: white;
          background: linear-gradient(
            -38deg,
            $myplane-grey-80 0%,
            $myplane-grey-80 33%,
            $myplane-grey-70 33%,
            $myplane-grey-70 100%
          );

          &:hover {
            color: $myplane-grey;
            background: $mp-yellow;
          }
        }
      }

      .separator {
        font-size: 15px;
        font-weight: 600;
        margin: 24px auto;
        position: relative;
        overflow: hidden;
        width: 100px;
        text-align: center;

        .text {
          display: inline-flex;
          position: relative;
          padding: 0 8px;
          z-index: 9999;

          &:before,
          &:after {
            content: '';
            display: block;
            width: 30px;
            position: absolute;
            top: 10px;
            border-top: 1px solid;
          }

          &:before {
            right: 100%;
          }

          &:after {
            left: 100%;
          }
        }
      }

      a {
        color: $mp-blue;

        :hover {
          color: $mp-yellow;
        }
      }

      .logo {
        margin-bottom: 40px;
        max-width: 360px;
      }
    }
  }
}

// GOOGLE MAPS
.google-map-marker-label,
.google-map-cluster-marker-label {
  padding: 3px;
  border-radius: 4px;
  white-space: normal;
  max-width: 80px;
  background-color: $mp-yellow;
  color: $mp-blue !important;
}

google-map .map-container {
  height: 100% !important;
  min-height: 400px;
}

.card {
  border-top: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15) !important;
}

// BLUR EFFECT (please use blur-overlay component)
.blurred {
  display: block;
  filter: blur(8px);
  touch-action: none !important;
  -ms-touch-action: none !important;
  -webkit-touch-callout: none !important;
  pointer-events: none !important;
  -webkit-touch-callout: none !important; /* iOS Safari */
  -webkit-user-select: none !important; /* Safari */
  -khtml-user-select: none !important; /* Konqueror HTML */
  -moz-user-select: none !important; /* Old versions of Firefox */
  -ms-user-select: none !important; /* Internet Explorer/Edge */
  user-select: none !important; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

  * {
    touch-action: none !important;
    -ms-touch-action: none !important;
    -webkit-touch-callout: none !important;
    pointer-events: none !important;
    -webkit-touch-callout: none !important; /* iOS Safari */
    -webkit-user-select: none !important; /* Safari */
    -khtml-user-select: none !important; /* Konqueror HTML */
    -moz-user-select: none !important; /* Old versions of Firefox */
    -ms-user-select: none !important; /* Internet Explorer/Edge */
    user-select: none !important; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

    ::selection {
      // For some reason, some mat-label elements are still highlightable, this hides the highlight
      background: none !important;
    }
  }
}

/* Previous value component History tooltip spacing **/
.history-tooltip {
  white-space: pre-line;
}

/** Round input **/

.round-input {
  .mdc-notched-outline {
    .mdc-notched-outline__leading {
      border-radius: 30px 0 0 30px !important;
      border-right: none !important;
      width: 30px !important;
      background-color: white;
      z-index: 1;
    }

    .mdc-notched-outline__notch {
      border-right: none !important;
      border-left: none !important;
      background-color: white;
      z-index: 1;
    }

    .mdc-notched-outline__trailing {
      border-radius: 0 30px 30px 0 !important;
      border-left: none !important;
      background-color: white;
      z-index: 1;
    }
  }

  .mat-mdc-form-field-infix {
    position: relative;
    z-index: 2;
  }

  input.mat-mdc-input-element {
    position: relative;
    z-index: 2;
    padding-left: 5px;
  }
}

.mat-mdc-form-field {
  height: fit-content !important;
}

.mat-mdc-form-field-hint-wrapper {
  position: relative !important;
}

.mat-mdc-form-field-subscript-wrapper {
  line-height: 16px !important;
}

.mat-mdc-form-field-hint-spacer {
  display: none;
}
