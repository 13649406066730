// -----------------------------------------------------------------------------------------------------
@use '@angular/material' as mat;
@import 'src/@fuse/scss/partials/colors';

// -----------------------------------------------------------------------------------------------------
// @ Custom color maps for MyPlane
// -----------------------------------------------------------------------------------------------------

.smallgray {
  font-size: 0.8em;
  color: #777;
}

// REMOVE ARROWS from number fields

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

// Function for returning the tint for a specific color.
// The percentage value has to be the difference between 100 and the the actual tint value
@function tint($color, $percentage) {
  @return mix(#fff, $color, $percentage);
}

$mp-blue: #142438;
$mp-yellow: #f3e41a;
$mp-grey: #1d1d1b;
$myplane-yellow: (
  100: $mp-yellow,
  80: tint($mp-yellow, 20%),
  60: tint($mp-yellow, 40%),
  40: tint($mp-yellow, 60%),
  30: tint($mp-yellow, 70%),
  20: tint($mp-yellow, 80%),
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$myplane-grey: (
  100: $mp-grey,
  80: tint($mp-grey, 20%),
  60: tint($mp-grey, 40%),
  40: tint($mp-grey, 60%),
  30: tint($mp-grey, 70%),
  20: tint($mp-grey, 80%),
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$myplane-new: (
  500: #142438,
  contrast: (
    500: #f3e41a,
  ),
);
$myplane-new-inverted: (
  50: #f3e41a,
  100: #f3e41a,
  200: #f3e41a,
  300: #f3e41a,
  400: #f3e41a,
  500: #f3e41a,
  600: #f3e41a,
  700: #f3e41a,
  800: #f3e41a,
  900: #f3e41a,
  contrast: (
    500: #142438,
  ),
);
$myplane-blue: (
  50: #eaf1ff,
  100: #cbdbff,
  200: #a9c4ff,
  300: #87acfe,
  400: #6d9afe,
  500: #5388fe,
  600: #4c80fe,
  700: #4275fe,
  800: #396bfe,
  900: #2958fd,
  A100: #ffffff,
  A200: #ffffff,
  A400: #d4ddff,
  A700: #bbc9ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$myplane-pink: (
  50: #fae2eb,
  100: #f3b8cd,
  200: #ec88ac,
  300: #e4588b,
  400: #de3572,
  500: #d81159,
  600: #d40f51,
  700: #ce0c48,
  800: #c80a3e,
  900: #bf052e,
  A100: #ffe8ec,
  A200: #ffb5c1,
  A400: #ff8297,
  A700: #ff6981,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$myplane-dark-blue: (
  50: #e3e5e8,
  100: #b8bfc5,
  200: #89949e,
  300: #5a6977,
  400: #36495a,
  500: #13293d,
  600: #112437,
  700: #0e1f2f,
  800: #0b1927,
  900: #060f1a,
  A100: #5998ff,
  A200: #2678ff,
  A400: #005bf2,
  A700: #0052d9,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
$myplane-green: (
  100: rgb(160, 190, 160),
  200: rgb(163, 175, 163),
  300: rgb(126, 160, 126),
  400: rgb(118, 170, 118),
  500: rgb(88, 170, 88),
  600: rgb(100, 177, 100),
  700: rgb(61, 167, 61),
  800: rgb(29, 173, 29),
  900: rgb(0, 170, 0),
  contrast: (
    50: #ffffff,
  ),
);

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------

// Angular Material typography
$typography: mat.define-typography-config(
  $font-family: 'Lato, Roboto, Helvetica Neue, Arial, sans-serif',
  $subtitle-2: mat.define-typography-level(14px, 24, 600),
  // $body-2: mat.define-typography-level(14px, 24, 600), // Breaks radio buttons and checkboxes
  $button: mat.define-typography-level(14px, 14, 600),
  // line-height must be unitless
  $body-1: mat.define-typography-level(16px, 1.125, 400),
);

$typography2: mat.define-typography-config(
  $font-family: 'Lato, Roboto, Helvetica Neue, Arial, sans-serif',
  $headline-6: mat.define-typography-level(20px, 32, 600),
  // line-height must be unitless !!!
  $headline-5: mat.define-typography-level(20px, 32, 600),
);

//Custom Gradients
$gradientOficial: linear-gradient(90deg, rgba(119, 114, 252, 1) 43%, rgba(82, 137, 254, 1) 100%);
$purpleMyPlane: rgba(119, 114, 252, 1);
$blueMyPlane: rgba(82, 137, 254, 1);

$mp-blue: #142438;
$mp-yellow: #f3e41a;
$mp-greyDark: #1d1d1b;
$mp-greyDark1: #1d1d1bda;

// Setup the typography
// Legacy typographies were removed here
@include mat.all-component-typographies($typography);
@include mat.all-component-typographies($typography);
//@include mat-core($typography);
// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------

// Import app and page component themes here to enable theming for them
@import 'src/app/layout/components/toolbar/toolbar.theme';

// @import "src/app/main/chat/chat.theme";
@import 'src/app/main/pages/coming-soon/coming-soon.theme';

// Authentication
@import 'src/app/main/authentication/login/login.theme';
@import 'src/app/main/authentication/reset-password/reset-password.theme';
@import 'src/app/main/authentication/forgot-password/forgot-password.theme';
@import 'src/app/main/authentication/mail-confirm/mail-confirm.theme';

// Modules
@import 'src/app/main/faq/faq.theme';

// Define a mixin for easier access
@mixin components-theme($theme) {
  // Layout components
  @include toolbar-theme($theme);

  // Modules
  @include login-theme($theme);
  @include faq-theme($theme);

  // Apps
  // @include chat-theme($theme);

  // Pages
  @include reset-password-theme($theme);
  @include forgot-password-theme($theme);
  @include mail-confirm-theme($theme);

  // Pages
  @include coming-soon-theme($theme);

  // UI
  //@include ui-cards-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette($myplane-dark-blue);
$default-accent-palette: mat.define-palette($myplane-new-inverted);
$default-warn-palette: mat.define-palette($myplane-pink);
$myPlane-accent-palette: mat.define-palette($myplane-new-inverted);
$default-success-palette: mat.define-palette($myplane-green);
// Create the Material theme object
$theme: mat.define-light-theme(
  (
    color: (
      primary: $default-primary-palette,
      accent: $default-accent-palette,
      warn: $default-warn-palette,
    ),
  )
);

// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------
$fuse-white: (
  50: white,
  100: white,
  200: white,
  300: white,
  400: white,
  500: white,
  600: white,
  700: white,
  800: white,
  900: white,
  contrast: (
    500: mat.get-color-from-palette($default-primary-palette, 'text'),
  ),
);

$fuse-black: (
  500: black,
  contrast: (
    500: mat.get-color-from-palette($default-accent-palette, 'text'),
  ),
);

// Generate Fuse color classes for custom palettes
$custom_palettes: (
  fuse-white: $fuse-white,
  fuse-black: $fuse-black,
  myplane-blue: $myplane-blue,
  myplane-pink: $myplane-pink,
  myplane-dark-blue: $myplane-dark-blue,
  myplane-green: $myplane-green,
  myplane-grey: $myplane-grey,
);
@include fuse-color-classes($custom_palettes);

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {
  // Create an Angular Material theme from the $theme map
  // Legacy themes were removed from here
  @include mat.all-component-themes($theme);
  @include mat.all-component-themes($theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($theme);

  // Apply the theme to the user components
  @include components-theme($theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $default-primary-palette,
    accent: $myPlane-accent-palette,
    success: $default-success-palette,
    warn: $default-warn-palette,
  );

  @include fuse-color-classes($palettes);

  a {
    color: mat.get-color-from-palette($myplane-pink, 500);
  }

  .mat-mdc-tooltip {
    font-size: 15px !important;
  }

  .mat-toolbar.mat-accent {
    background: $mp-yellow;
  }
  mat-dialog-container {
    padding: 0;
    mat-dialog-content {
      margin: unset;
    }
    .mat-mdc-dialog-content {
      //margin: 15px; // Dialog content uses custom padding. If the dialog no margin/padding bug happens put this back.
    }
  }
  mat-header-row {
    border-radius: 8px 8px 0 0;
    // background: $gradientOficial;
    mat-header-cell {
      // color:white !important;
    }
  }

  fuse-confirm-dialog,
  app-confirm-dialog {
    .mat-mdc-dialog-actions {
      margin: 1em 1em 1em 1em;
      padding: unset;
      min-height: unset;
    }

    // Do not remove comment below in case the zero margin dialog bug returns
    .mat-mdc-dialog-content {
      // margin: 10px; // Dialog content uses custom padding. If the dialog no margin/padding bug happens put this back.
    }
  }

  .mat-mdc-unelevated-button {
    border-radius: 1.5em !important;
  }

  form button:not(:first-child) {
    margin-left: 1em;
  }

  app-page-header button:not(:first-child) {
    margin-left: 1em;
  }
  mat-dialog-actions button:not(:first-child) {
    margin-left: 1em !important;
  }

  // mat-row:nth-child(odd){
  //     background-color:#E0E7F0;
  // }
  // mat-row:nth-child(even){
  //     background-color:#F1F4F8;
  // }

  .header {
    background: none !important;
    color: #131523 !important;
    .logo {
      .logo-text,
      .logo-icon {
        font-size: 30px;
        font-weight: 600;
      }
    }
  }

  .fuse-card {
    box-shadow: 1px 5px 6px #00000024;
  }
  .titleComponent {
    font-weight: bold;
  }
  button.gradientOficial {
    background: $gradientOficial;
    color: white;
  }
  button.mp-yellow {
    background: $mp-yellow;
    color: $mp-blue;
  }
  button.mp-blue {
    background: $mp-blue;
    color: white;
  }
  button.mp-green {
    background: #179e50;
    color: white;
  }
  button.w-10 {
    width: 10%;
  }
  button.w-20 {
    width: 20%;
  }
  button.w-30 {
    width: 30%;
  }
  div.m-10 {
    margin: 10px;
  }
  div.m-15 {
    margin: 15px;
  }
  div.m-20 {
    margin: 20px;
  }
  div.m-30 {
    margin: 30px;
  }
  div.m-40 {
    margin: 40px;
  }
  .mat-mdc-paginator {
    border-radius: 12px;
  }

  .myPlane-card {
    border: 2px solid transparent !important;
    .mat-card-header-text {
      margin: unset;
    }
  }
  .mat-form-field-disabled
    .mat-mdc-text-field-wrapper
    .mat-mdc-form-field-flex
    .mdc-notched-outline {
    background-color: #f5f5f5 !important;
    z-index: 0;
  }
  .mat-form-field-appearance-outline.mat-form-field-disabled input {
    color: black !important;
    font-weight: 400 !important;
  }
  .mat-form-field-appearance-outline.mat-form-field-disabled textarea {
    color: black !important;
    font-weight: 400 !important;
  }
  .mat-form-field-appearance-outline.mat-form-field-disabled span {
    color: black !important;
    font-weight: 400 !important;
  }

  button.text-button {
    border: none;
    background: none;
    margin: 0 !important;
    color: $mp-blue;
    cursor: pointer;
    text-decoration: none;
    padding: 0;

    &:hover {
      text-decoration: underline;
    }

    &[disabled] {
      text-decoration: none;
      cursor: auto;
      color: inherit;
    }
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Define a dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$dark-theme-primary-palette: mat.define-palette($fuse-white);
$dark-theme-accent-palette: mat.define-palette($myplane-pink, 600, 400, 700);
$dark-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $dark-theme-primary-palette,
      accent: $dark-theme-accent-palette,
      warn: $dark-theme-warn-palette,
    ),
  )
);
